<template>
  <div>
    <base-alert v-if="alert.message.length > 0" ref="alert" :type="alert.type">
      <template v-slot:text>
        {{ $t(alert.message) }}
      </template>
    </base-alert>

    <div role="form" ref="registerForm">
      <div class="row">
        <div class="col-md-6 col-sm-12 mt-2 mt-md-0">
          <base-input-old
            ref="givenName"
            :label="$t('landing.form.first-name')"
            class="input-group-alternative mb-0"
            :placeholder="$t('landing.form.first-name-placeholder')"
            addon-left-icon="fa fa-user"
            :valid="isValid('givenName')"
            v-model="model.givenName"
          ></base-input-old>
        </div>
        <div class="col-md-6 col-sm-12 mt-2 mt-md-0">
          <base-input-old
            ref="familyName"
            :label="$t('landing.form.last-name')"
            class="input-group-alternative mb-0"
            :placeholder="$t('landing.form.last-name-placeholder')"
            addon-left-icon="fa fa-id-badge"
            :valid="isValid('familyName')"
            v-model="model.familyName"
          ></base-input-old>
        </div>
      </div>
      <base-input-old
        ref="email"
        :label="$t('landing.form.email')"
        class="input-group-alternative mb-0 mt-2"
        :placeholder="$t('landing.form.email-placeholder')"
        addon-left-icon="fa fa-envelope"
        :tooltip="true"
        :valid="isValid('email')"
        v-model="model.email"
        :disabled="isDisabled.email"
      >
        <template v-slot:tooltip-content>
          <h5 class="my-2">{{ $t('landing.form.invalid-email') }}</h5>
        </template>
      </base-input-old>

      <base-input-old
        ref="password"
        :label="$t('landing.form.password')"
        class="input-group-alternative mb-0 mt-2"
        :class="checkPassword"
        :placeholder="$t('landing.form.password-placeholder')"
        type="password"
        addon-left-icon="fa fa-key"
        :show-or-hide="true"
        :tooltip="true"
        :valid="isValid('password')"
        name="password"
        v-model="model.password"
      >
        <template v-slot:tooltip-content>
          <h4>{{ $t('landing.form.tooltip.password-title') }}</h4>
          <i class="fa fa-minus info-content-icon" :class="longEnoughClass"></i>
          {{ $t('landing.form.tooltip.long-enough') }}<br />
          <i
            class="fa fa-font info-content-icon"
            :class="containLettersClass"
          ></i>
          {{ $t('landing.form.tooltip.contain-letters') }}<br />
          <i
            class="fa fa-hashtag info-content-icon"
            :class="containNumberClass"
          ></i>
          {{ $t('landing.form.tooltip.contain-numbers') }}<br />
          <i class="fa fa-at info-content-icon" :class="containSpecialClass"></i
          >{{ $t('landing.form.tooltip.contain-specials') }}
        </template>
      </base-input-old>

      <base-input-old
        ref="company"
        :label="$t('landing.form.company')"
        class="input-group-alternative mb-0 mt-2"
        :placeholder="$t('landing.form.company-placeholder')"
        addon-left-icon="fa fa-building"
        :valid="isValid('company')"
        v-model="model.company"
        :disabled="isDisabled.company"
      ></base-input-old>

      <div class="row my-3">
        <div class="col-12">
          <base-checkbox
            ref="policyOptIn"
            class="mb-0"
            v-model="checkboxes.unchecked"
          >
            <span class="text-muted">
              {{ $t('landing.form.tcu-1') }}
              <a
                href="https://www.mayday.fr/legal/conditions-generales"
                target="_blank"
                >{{ $t('landing.form.tcu-2') }}</a
              >
            </span>
          </base-checkbox>
        </div>
      </div>
      <div class="text-center">
        <base-button-old
          ref="submit"
          type="primary"
          v-on:click.prevent="triggerRegistration()"
          class="register-button mt-3"
          :disabled="cannotSignup"
        >
          <div v-if="isRegisterLoading" role="status">
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </div>
          <span v-else>{{ $t('landing.register') }}</span>
        </base-button-old>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email as vEmail } from 'vuelidate/lib/validators';
import BaseAlert from '@/components/BaseAlert.vue';
import BaseButtonOld from '@/components/BaseButtonOld.vue';
import BaseInputOld from '@/components/BaseInputOld.vue';
import BaseCheckbox from '@/components/BaseCheckbox.vue';

export default {
  name: 'register-form',
  components: {
    BaseAlert,
    BaseButtonOld,
    BaseInputOld,
    BaseCheckbox,
  },
  props: {
    email: {
      type: String,
      default: '',
    },
    company: {
      type: String,
      default: '',
    },
    isRegisterLoading: {
      required,
      default: false,
      type: Boolean,
    },
    alert: {
      required,
      default: () => ({
        message: '',
        type: '',
      }),
      type: Object,
    },
    disableEmailValidation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checkboxes: {
        unchecked: false,
      },
      model: {
        givenName: '',
        familyName: '',
        email: '',
        password: '',
        company: '',
      },
    };
  },
  created() {
    Object.assign(this.model, {
      company: this.$props.company,
      email: this.$props.email,
    });
  },
  computed: {
    isDisabled() {
      return {
        email: this.email !== '',
        company: this.company !== '',
      };
    },
    checkPassword() {
      return {
        passwordInvalid:
          this.$v.model.password.$invalid && this.model.password.length > 1,
      };
    },
    longEnoughClass() {
      return this.$v.model.password.longEnough ? 'has-success' : 'has-danger';
    },
    containLettersClass() {
      return this.$v.model.password.containUpperAndLowerLetters
        ? 'has-success'
        : 'has-danger';
    },
    containNumberClass() {
      return this.$v.model.password.containNumber
        ? 'has-success'
        : 'has-danger';
    },
    containSpecialClass() {
      return this.$v.model.password.containSpecialCaracter
        ? 'has-success'
        : 'has-danger';
    },
    cannotSignup() {
      return (
        this.isRegisterLoading ||
        this.$v.model.$invalid ||
        !this.checkboxes.unchecked
      );
    },
  },
  validations() {
    const email = {
      required,
      ...(this.disableEmailValidation ? {} : { email: vEmail }),
    };
    return {
      model: {
        givenName: {
          required,
        },
        familyName: {
          required,
        },
        email: email,
        password: {
          required,
          longEnough: (password) => {
            return password.length >= 8;
          },
          containUpperAndLowerLetters: (password) => {
            return /[a-z]/.test(password) && /[A-Z]/.test(password);
          },
          containNumber: (password) => {
            return /[0-9]/.test(password);
          },
          containSpecialCaracter: (password) => {
            return /\W|_/.test(password);
          },
        },
        company: {
          required,
        },
      },
    };
  },
  methods: {
    isValid(data) {
      if (this.model[data] === '') {
        return undefined;
      }
      return !this.$v.model[data].$invalid || this.model[data].length < 4;
    },
    clearInput() {
      this.model.givenName = '';
      this.model.familyName = '';
      this.model.email = '';
      this.model.password = '';
      this.model.company = '';
    },
    async triggerRegistration() {
      this.$emit('submit', this.model);
    },
    enterClicked() {
      if (!this.cannotSignup) {
        this.$refs.submit.$el.children[0].click();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.passwordInvalid {
  border: 1px solid $red-mayday;
}

.register-button {
  min-width: 150px;
}

.has-success:after,
.has-danger:after {
  display: none;
}

.has-success {
  color: rgba($blue-mayday, 0.4);
}

.has-danger {
  color: rgba($red-mayday, 0.4);
}
</style>
