<template>
  <div>
    <div class="card bg-secondary shadow border-0" id="login-box">
      <div class="card-body px-md-5 py-md-5 md-divider">
        <div v-if="!hasResetAsOrigin">
          <div
            class="text-center text-muted mb-4"
            v-if="!warningAttempts && !alert.message.length"
          >
            <h1 class="title">{{ $t('landing.form.login-context') }}</h1>
          </div>

          <base-alert v-if="maxAuthAttempts > 2" ref="warning" type="danger">
            <template v-slot:text>
              {{ $t('landing.warning-login-attempts-1') }}
              <div class="mr-2 d-inline-block">
                <router-link to="/reset" class="text-underline">
                  {{ $t('landing.warning-login-attempts-2') }}
                </router-link>
                {{ $t('landing.warning-login-attempts-3') }}
                {{ maxAuthAttempts
                }}{{ $t('landing.warning-login-attempts-4') }}
              </div>
            </template>
          </base-alert>
        </div>

        <base-alert v-else ref="reset" type="success">
          <template v-slot:text>{{ $t('landing.login-after-reset') }}</template>
        </base-alert>

        <base-alert
          v-if="alert.message.length > 0 && !warningAttempts"
          ref="alert"
          :type="alert.type"
        >
          <template v-slot:text>
            {{ $t(alert.message) }}
          </template>
        </base-alert>

        <LoginForm
          v-if="!disabledAuthProtocols.creds"
          ref="form"
          @submit="tryToLogin"
          :isLoginLoading="isLoginLoading"
        ></LoginForm>
        <div
          class="d-flex align-items-center text-muted"
          v-if="shouldShowOrDivider"
        >
          <el-divider></el-divider>
          <small class="px-2">{{ $t('landing.form.or') }}</small>
          <el-divider></el-divider>
        </div>
        <LoginSso
          :disabled-auth-protocols="disabledAuthProtocols"
          :preferred-sso-protocol="preferredSsoProtocol"
        />
      </div>
    </div>
    <LoginFooter />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { permissionsRedirection } from '@/utils/permissionsRedirection';
import LoginForm from './LoginForm/LoginForm.vue';
import LoginSso from './LoginSso/LoginSso.vue';
import LoginFooter from './LoginFooter/LoginFooter.vue';

export default {
  name: 'login',
  components: {
    LoginForm,
    LoginSso,
    LoginFooter,
  },
  data() {
    return {
      alert: {
        message: '',
        type: '',
      },
      isLoginLoading: false,
    };
  },
  beforeCreate() {
    // we call $store in beforeCreate since methods (hence
    // mapActions) are not loaded yet
    this.$store.dispatch('fetchCustomConfig', window.location.host);
  },
  created() {
    if (this.$route.query.token) {
      const { token } = this.$route.query;
      this.tryToLoginOboToken(token);
    }
    if (this.$route.query.status === 'SESSION_EXPIRED') {
      this.alert = {
        message: this.$t('landing.login-session-error'),
        type: 'success',
      };
    } else if (this.$route.query.code) {
      this.alert = {
        message: this.$t('landing.login-provider-error'),
        type: 'danger',
      };
    }
  },
  computed: {
    hasResetAsOrigin() {
      if (Object.keys(this.$route.query).includes('origin')) {
        return this.$route.query.origin === 'reset';
      }
      return false;
    },
    disabledAuthProtocols() {
      const isSupport = this.$route.query.support === 'true';
      if (isSupport) return {};
      return this.customConfig.disabled_auth_protocols || {};
    },
    preferredSsoProtocol() {
      return this.customConfig.preferred_sso_protocol || 'saml';
    },
    shouldShowOrDivider() {
      const hasSso = !(
        this.disabledAuthProtocols.sso && this.disabledAuthProtocols.google
      );
      const hasCreds = !this.disabledAuthProtocols.creds;
      return hasSso && hasCreds;
    },
    ...mapGetters([
      'maxAuthAttempts',
      'warningAttempts',
      'tooManyAttempts',
      'customConfig',
    ]),
  },
  methods: {
    async tryToLogin(data) {
      this.isLoginLoading = true;
      if (this.$route.name === 'login-former') {
        await this.login(data);
      } else {
        await this.loginWithCredentials(data);
      }
      this.isLoginLoading = false;

      const { userRole, userPermissions, isVerified } = this.$store.state;
      const { isParametric } = this.$store.getters;

      if (!userRole) {
        this.alert = {
          message: this.$t('landing.login-credentials-error'),
          type: 'danger',
        };
        this.$refs.form.$refs.password.$refs.password.select();
        if (this.tooManyAttempts) {
          this.setBlockedLoginStartTime();
          this.$router.push({ name: '/reset' });
        }
        return this.redirectAccordingToPermissions(
          userPermissions,
          isParametric,
        );
      }
      if (isVerified) {
        return this.redirectAccordingToPermissions(
          userPermissions,
          isParametric,
        );
      }
      this.alert = {
        message: this.$t('landing.login-pending-error'),
        type: 'danger',
      };
    },
    async tryToLoginOboToken(token) {
      this.isLoginLoading = true;
      const res = await this.loginFromOboToken(token);
      this.isLoginLoading = false;

      if (res === false) {
        this.alert = {
          message: this.$t('landing.login-credentials-error'),
          type: 'danger',
        };
        return;
      }

      const { userRole, userPermissions, isVerified } = this.$store.state;
      const { isParametric } = this.$store.getters;

      if (!userRole) {
        this.alert = {
          message: this.$t('landing.login-credentials-error'),
          type: 'danger',
        };
        this.$refs.form.$refs.password.$refs.password.select();
        if (this.tooManyAttempts) {
          this.setBlockedLoginStartTime();
          this.$router.push({ name: '/reset' });
        }
        return this.redirectAccordingToPermissions(
          userPermissions,
          isParametric,
        );
      }
      if (isVerified) {
        return this.redirectAccordingToPermissions(
          userPermissions,
          isParametric,
        );
      }
      this.alert = {
        message: this.$t('landing.login-pending-error'),
        type: 'danger',
      };
    },
    redirectAccordingToPermissions(permissions, isParametric = false) {
      let routeName =
        this.$route.query.redirect ||
        permissionsRedirection(permissions, isParametric);
      this.reloadOrRouter(routeName, !!this.$route.query.redirect);
    },
    reloadOrRouter(routeName, directPush) {
      if (routeName === 'mayday-web') {
        this.getUserInfos();
      }

      if (routeName === 'academy') {
        window.location.href = '/academy';
        return;
      }

      if (routeName === 'notification-center') {
        window.location.href = '/notifications';
        return;
      }

      if (routeName === 'hub') {
        window.location.href = '/hub';
        return;
      }

      if (routeName === 'home') {
        window.location.href = '/home';
        return;
      }

      if (directPush) {
        this.$router.push(routeName);
      } else {
        this.$router.push({
          name: routeName,
        });
      }
    },
    ...mapActions([
      'login',
      'loginWithCredentials',
      'loginFromOboToken',
      'setBlockedLoginStartTime',
    ]),
    ...mapActions('webKnowledgeModule', ['getUserInfos']),
  },
};
</script>

<style lang="scss" scoped>
#login-box {
  margin-top: -50px;
  background-color: rgba(255, 255, 255, 0.82) !important;
  border-radius: 4px !important;
}
.title {
  line-height: 1.2 !important;
}

.md-divider {
  :deep() .el-divider__text {
    background-color: $grey-1-mayday;
    color: $grey-6-mayday;
  }
}

.md-divider {
  :deep() .el-divider {
    margin: 12px 0;
  }
}
</style>
