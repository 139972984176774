<template>
  <div class="main-container">
    <div
      class="title-container"
      :class="{ opened: isOpen, divider: withRowDivider, reverse }"
    >
      <button
        @click="isOpen = !isOpen"
        class="open-button"
        :class="{ opened: isOpen }"
      >
        <font-awesome-icon
          class="chevron-icon"
          :icon="['fal', 'chevron-right']"
        />
      </button>

      <div class="content-container">
        <slot name="title" />
      </div>
    </div>
    <div v-if="isOpen" class="dropdown-container">
      <slot name="dropdown-content" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'settings-dropdown',
  props: {
    iconPosition: {
      type: String,
      default: 'left',
    },
    withRowDivider: {
      type: Boolean,
      default: true,
    },
    forceOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  mounted() {
    this.isOpen = this.forceOpen;
  },
  computed: {
    reverse() {
      return this.iconPosition === 'right';
    },
  },
  watch: {
    forceOpen(newVal) {
      this.isOpen = newVal;
    },
    isOpen(newVal) {
      if (!newVal) {
        this.$emit('close');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.title-container {
  display: flex;
  align-items: center;
  gap: 12px;
  padding-block: 8px;
  &.divider {
    border-bottom: 1px solid $grey-2-mayday;
  }
  &.reverse {
    flex-direction: row-reverse;
  }
}

.content-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.dropdown-container {
  padding-left: 32px;
  padding-block: 8px;
  border-bottom: 1px solid $grey-4-mayday;
}
.chevron-icon {
  width: 12px;
  height: 12px;
  margin: 4px;
  transition: transform 0.3s;
}
.open-button {
  padding: 0;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: $grey-1-mayday;
  }
  &.opened {
    .chevron-icon {
      transform: rotate(90deg);
    }
  }
}
</style>
