<template>
  <div :class="base" :style="typeStyle">
    <div :class="`${base}__main`">
      <div :class="`${base}__main__title`">{{ title }}</div>
      <div :class="`${base}__main__description`">{{ description }}</div>
    </div>
    <div :class="`${base}__cta`" v-if="cta">
      <base-button-old
        size="sm"
        type="primary"
        :outline="true"
        @click="$emit('click')"
        >{{ cta }}</base-button-old
      >
    </div>
  </div>
</template>
<script>
import { red5Mayday } from '@/assets/scss/_colors.scss';

export default {
  name: 'workflow-callout',
  props: {
    title: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    cta: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'primary',
    },
  },
  computed: {
    typeStyle() {
      let backgroundColor = 'white';
      let borderColor = '$grey-2-mayday';
      let color = 'black';
      switch (this.type) {
        case 'danger':
          backgroundColor = 'white';
          borderColor = red5Mayday;
          color = red5Mayday;
          break;
        default:
          break;
      }
      return {
        '--color': color,
        '--border-color': borderColor,
        '--background-color': backgroundColor,
      };
    },
  },
  data() {
    return {
      base: Object.freeze('workflow-callout'),
    };
  },
};
</script>
<style lang="scss">
.workflow-callout {
  display: flex;
  flex-direction: row;
  padding: 8px;
  border-radius: 6px;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  align-items: center;
  justify-content: space-between;

  &__main {
    color: var(--color);
    &__title {
      font-weight: bold;
      font-size: 12px;
    }
    &__description {
      font-size: 12px;
    }
  }
}
</style>
