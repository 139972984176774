var render = function render(){var _vm=this,_c=_vm._self._c;return _c('BaseModal',{attrs:{"custom-width":"400px","custom-height":"400px","button-size":"md","sidebar":false,"title":_vm.isMonolingual
      ? _vm.$t(
          'public-knowledge.configuration.items.language.add-language-modal.title-monolingual',
        )
      : _vm.$t(
          'public-knowledge.configuration.items.language.add-language-modal.title-multilingual',
        ),"description":_vm.isMonolingual
      ? _vm.$t(
          'public-knowledge.configuration.items.language.add-language-modal.description-monolingual',
        )
      : _vm.$t(
          'public-knowledge.configuration.items.language.add-language-modal.description-multilingual',
        ),"open":_vm.localDisplay,"confirm-text":_vm.$t('components.modals.update-user-label.confirm-button'),"disabled":!_vm.selectedValue},on:{"cancel":function($event){return _vm.$emit('close')},"confirm":_vm.handleAddLanguage,"close":function($event){return _vm.$emit('close')}}},[_c('div',{staticClass:"d-flex w-full h-full flex-col justify-content-start"},[_c('div',[_vm._v(_vm._s(_vm.$t('public-knowledge.configuration.items.language.title')))]),_c('base-select-wrapper',{staticClass:"w-full",attrs:{"options":_vm.filteredOptions,"icon":true,"value":_vm.selectedValue,"placeholder":_vm.$t('generic.choose')},on:{"select":function($event){_vm.selectedValue = $event}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }