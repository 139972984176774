<template>
  <div>
    <div class="pt-2" v-if="!disabledAuthProtocols.google">
      <base-button-old
        class="sso-btn"
        type="secondary"
        @click="openSso('google')"
      >
        <div class="d-flex">
          <img src="@/assets/google_logo.png" alt="google_logo.png" />
          <div class="connect-with-text">
            {{ $t('landing.form.googleSignin') }}
          </div>
        </div>
      </base-button-old>
    </div>
    <div class="pt-3" v-if="hasCustomDomain && !disabledAuthProtocols.sso">
      <base-button-old class="sso-btn" type="secondary" @click="openSso(null)">
        <div class="d-flex">
          <span class="d-flex align-items-center justify-content-center">
            <font-awesome-icon :icon="['fas', 'key']" class="sso-icon" />
          </span>
          <div class="connect-with-text">
            {{ $t('landing.form.sso') }}
          </div>
        </div>
      </base-button-old>
    </div>
  </div>
</template>

<script>
export default {
  name: 'login-sso',
  props: {
    disabledAuthProtocols: {
      type: Object,
      default: () => ({}),
    },
    preferredSsoProtocol: {
      type: String,
      default: 'saml',
    },
  },
  computed: {
    hasCustomDomain() {
      const url = new URL(window.location.href);
      const origin = url.origin;

      return origin !== process.env.VUE_APP_FRONT_URL;
    },
  },
  methods: {
    openSso(sso) {
      let ssoEndpoint = sso === 'google' ? 'google' : this.preferredSsoProtocol;
      try {
        window.location.href = `${
          process.env.VUE_APP_SSO_URL
        }/${ssoEndpoint}?state=${Buffer.from(
          this.stateResolution(this.$route.name, window.location.href),
        ).toString('base64')}`;
      } catch (err) {
        console.log(err);
      }
    },
    stateResolution(routeName, locationHref) {
      const curatedUrl = this.urlCuration(locationHref);
      if (routeName === 'login-former') return curatedUrl;
      return JSON.stringify({
        url: curatedUrl,
        source: 'app',
      });
    },
    urlCuration(rawUrl) {
      const [route, query] = rawUrl.split('?');
      if (query) {
        const curatedQuery = new URLSearchParams(query);
        // avoid 404 redirection after successfull login
        curatedQuery.delete('code');
        return `${route}?${curatedQuery.toString()}`;
      }
      return rawUrl;
    },
  },
};
</script>

<style scoped lang="scss">
.sso-btn {
  width: 100%;
  box-shadow: $shadow-mayday;
}
.sso-btn img {
  width: 20px;
  height: 20px;
}
.sso-btn .connect-with-text {
  padding-left: 16px;
  font-size: 14px;
}

.sso-icon {
  font-size: 20px;
}
</style>
