<template>
  <el-table
    :data="tableData"
    class="table-style"
    :default-sort="{ prop: 'date', order: 'descending' }"
    :cell-style="{
      'background-color': '#f8f9fe',
      width: '100%',
      'white-space': 'nowrap',
      overflow: 'hidden',
      'text-overflow': 'ellipsis',
    }"
    :header-cell-style="{ 'background-color': '#f8f9fe', 'border-top': 'none' }"
  >
    <el-table-column
      prop="date"
      :label="$t('analytics-download.date')"
      width="120"
      sortable
    >
    </el-table-column>
    <el-table-column
      prop="type"
      :label="$t('analytics-download.type')"
      width="120"
    >
    </el-table-column>
    <el-table-column
      prop="name"
      :label="$t('analytics-download.name')"
      width="230"
    >
    </el-table-column>
    <el-table-column prop="address" :label="$t('analytics-download.address')">
      <template slot-scope="scope">
        <a :href="scope.row.address" class="address-link">{{
          scope.row.address
        }}</a>
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('analytics-download.action')"
      width="100"
      align="center"
    >
      <template slot-scope="scope">
        <el-tooltip :content="$t('analytics-download.download')">
          <div class="download-icon-wrapper">
            <font-awesome-icon
              @click="handleDownload(scope.row)"
              class="download-icon"
              :icon="['fal', 'arrow-to-bottom']"
            ></font-awesome-icon></div
        ></el-tooltip>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: 'analytics-download-table',
  props: {
    tableData: Array,
  },
  methods: {
    handleDownload(e) {
      window.open(e.address, '_blank');
    },
  },
};
</script>
<style lang="scss" scoped>
.download-icon-wrapper:hover {
  cursor: pointer;
  .download-icon {
    color: $blue-mayday;
  }
}
.address-link {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-style {
  width: 100%;
  padding: 0px 10px;
  background-color: $body-bg;
}
</style>
