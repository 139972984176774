<template>
  <BaseModal
    custom-width="400px"
    custom-height="400px"
    button-size="md"
    :sidebar="false"
    :title="
      isMonolingual
        ? $t(
            'public-knowledge.configuration.items.language.add-language-modal.title-monolingual',
          )
        : $t(
            'public-knowledge.configuration.items.language.add-language-modal.title-multilingual',
          )
    "
    :description="
      isMonolingual
        ? $t(
            'public-knowledge.configuration.items.language.add-language-modal.description-monolingual',
          )
        : $t(
            'public-knowledge.configuration.items.language.add-language-modal.description-multilingual',
          )
    "
    :open="localDisplay"
    :confirm-text="$t('components.modals.update-user-label.confirm-button')"
    :disabled="!selectedValue"
    @cancel="$emit('close')"
    @confirm="handleAddLanguage"
    @close="$emit('close')"
  >
    <div class="d-flex w-full h-full flex-col justify-content-start">
      <div>{{ $t('public-knowledge.configuration.items.language.title') }}</div>
      <base-select-wrapper
        class="w-full"
        :options="filteredOptions"
        :icon="true"
        :value="selectedValue"
        :placeholder="$t('generic.choose')"
        @select="selectedValue = $event"
      />
    </div>
  </BaseModal>
</template>

<script>
import { langOptions } from '@/utils/langOptions.js';

export default {
  components: {},
  props: {
    display: {
      type: Boolean,
      required: true,
    },
    isMonolingual: {
      type: Boolean,
      default: false,
    },
    existingLanguages: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      localDisplay: this.display,
      selectedValue: '',
      options: langOptions,
    };
  },
  computed: {
    filteredOptions() {
      return this.formattedLangOptions.filter(
        (n) => !this.existingLanguages.includes(n.key),
      );
    },
    formattedLangOptions() {
      return this.options.map((lang) => {
        return {
          key: lang,
          label: this.$t(`knowledge.languages.${lang}`),
        };
      });
    },
  },
  watch: {
    localDisplay() {
      if (!this.localDisplay) this.$emit('close');
    },
  },
  methods: {
    handleAddLanguage() {
      const event = this.isMonolingual ? 'set-multilingual' : 'new-language';
      this.$emit(event, this.selectedValue);
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.blue-mayday {
  color: $blue-mayday;
}

.icon {
  width: 24px;
  height: 24px;
}

.subcontent {
  border: 1px solid $grey-3-mayday;
  border-radius: 2px;
  height: 75%;
}
</style>
