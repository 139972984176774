<template>
  <div :class="`${base}`">
    <span :class="`${base}-readonly`" v-if="!editable && !value.icon">
      {{ type === 'input' ? value : value.label }}
    </span>
    <span :class="`${base}-readonly`" v-else-if="!editable && value.icon">
      <font-awesome-icon :icon="value.icon" />
    </span>
    <span :class="`${base}-editable`" v-else>
      <base-input
        v-if="type === 'input'"
        :model-value="localValue"
        @update:model-value="handleSelectChange($event.detail[0])"
        :placeholder="placeholder"
      />
      <base-select-wrapper
        v-else-if="type === 'select'"
        :class="`${base}-editable__select`"
        :value="keyValue"
        :options="options"
        :placeholder="placeholder"
        :multiple="false"
        :searchable="true"
        :icon="true"
        value-key="id"
        size="md"
        @select="handleSelectChange"
      />
    </span>
  </div>
</template>
<script>
const base = 'sso-attributes-mapping-input';

export default {
  name: 'SsoAttributesMappingInput',
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'input',
    },
    value: {
      default: null,
    },
    options: {
      type: Array,
    },
    placeholder: {
      type: String,
    },
  },
  data() {
    return {
      base: `${base}`,
      localValue: null,
    };
  },
  methods: {
    handleSelectChange(selection) {
      let selectedOption;
      if (this.type === 'select')
        selectedOption = this.options.filter((op) => op.key === selection)[0];
      if (this.type === 'input') selectedOption = selection;
      this.localValue = selectedOption;
      this.$emit('input', selectedOption);
    },
  },
  computed: {
    keyValue() {
      if (this.type === 'input') return this.value;
      return this.value.key;
    },
  },
  mounted() {
    this.localValue = this.value;
  },
};
</script>
<style lang="scss" scoped>
.sso-attributes-mapping-input {
  width: 100%;
  display: flex;
  align-items: center;
  &-editable {
    width: 100%;
    :deep() .el-input--md {
      height: 30px;
      .el-input__inner {
        height: 30px;
        line-height: 30px;
        padding: 0px 8px;
      }
    }
  }

  &-readonly {
    padding-left: 8px;
    font-size: 14px;
    word-break: break-all;
  }
}
</style>
