<template>
    <div class="settings-menu">
      <div
        v-for="item in data"
        class="settings-menu-category"
        :key="item.title"
      >
        <div class="settings-menu-category-title">
            {{ $t(item.title) }}
        </div>
        <div class="settings-menu-options-wrapper">
          <router-link
            v-for="children in item.children"
            :key="children.title"
            :to="{
              name: linkName(children),
              hash: children.hash,
            }"
            class="settings-menu-option"
          >
            <font-awesome-icon
              :icon="['fal',children.icon]"
              class="setting-menu-option-icon"
            />
            <font-awesome-icon
              :icon="['fas',children.icon]"
              class="setting-menu-option-active-icon"
            />
            <div>{{ $t(children.title) }}</div>
          </router-link>
        </div>
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'settings-layout-menu',
  props: {
    data: Array,
    knowledgeType: {
      // = public or private
      type: String,
      required: false,
    },
  },
  data() {
    return {
      activeBlock: null,
      blockEls: [],
      blockBreakpoints: [],
      idBlocks: [],
    };
  },
  computed: {
    ...mapGetters('publicKnowledgeModule', ['focusCompanyPublicConfig']),
  },
  methods: {
    linkName(item){
      if(!this.knowledgeType){
        return item.name;
      }
      return `${this.knowledgeType}-${item.name}`;
    }
  }
};
</script>

<style lang="scss" scoped>

.setting-menu {
  width: 100%;
}
.settings-menu-category {
  color: $grey-9-mayday;
  font-size: 14px;
  margin-bottom: 12px;
}
.settings-menu-category-title {
  margin-bottom: 8px;
  font-size: 12px;
  color: $grey-7-mayday;
}

.settings-menu-option {
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;
  border-left: 2px solid transparent;
  color: $grey-9-mayday;
  padding-left: 8px;

  &:hover {
    border-color: $grey-2-mayday;
  }

  &.active {
    border-color: $blue-mayday !important;
    font-weight: bold;

    .setting-menu-option-icon {
      display: none;
    }
    .setting-menu-option-active-icon {
      display: block;
    }
  }
}

.setting-menu-option-icon {
  margin-right: 8px;
}
.setting-menu-option-active-icon {
  color: $blue-mayday;
  display:none;
  margin-right: 8px;
}
</style>
