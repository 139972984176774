<template>
  <div
    class="d-flex col-12 pt-lg-2 font-weight-bold text-white justify-content-center text-center"
  >
    <p class="my-0">
      {{ $t('landing.form.login-footer-1') }}
      <router-link to="/register" class="text-underline dark-link">
        {{ $t('landing.form.login-footer-2') }}
      </router-link>
    </p>
  </div>
</template>

<script>
export default {
  name: 'login-footer',
};
</script>

<style scoped lang="scss">
.dark-link {
  color: $unknown-blue-2-mayday;
}
</style>
