<template>
  <div>
    <div class="card bg-secondary shadow border-0" id="register-box">
      <div class="card-body px-md-5 py-md-5 pb-md-3">
        <div class="text-center text-muted mb-4">
          <h1 class="title">{{ $t('landing.form.register-context') }}</h1>
        </div>
        <RegisterForm
          ref="form"
          @submit="tryToRegister"
          :isRegisterLoading="isRegisterLoading"
          :alert="alert"
        ></RegisterForm>
      </div>
    </div>

    <div
      class="d-flex col-12 pt-lg-2 font-weight-bold text-white justify-content-center text-center"
    >
      <p class="my-0">
        {{ $t('landing.form.register-footer-1') }}<br />
        <router-link to="/login" class="text-underline dark-link">
          {{ $t('landing.form.register-footer-2') }}
        </router-link>
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import RegisterForm from './RegisterForm/RegisterForm';

export default {
  name: 'register',
  data() {
    return {
      isRegisterLoading: false,
      alert: {
        message: '',
        type: '',
      },
    };
  },
  components: {
    RegisterForm,
  },
  methods: {
    async tryToRegister(data) {
      const onlyNotify = this.$route.name === 'register';
      this.isRegisterLoading = true;
      await this.register({ ...data, onlyNotify });
      this.alert = this.$store.state.status;
      this.isRegisterLoading = false;
      this.$refs.form.clearInput();
    },
    ...mapActions(['register']),
  },
};
</script>

<style scoped lang="scss">
#register-box {
  margin-top: -50px;
  background-color: rgba(255, 255, 255, 0.82) !important;
  border-radius: 4px !important;
}

.title {
  line-height: 1.2 !important;
}

.text-underline {
  text-decoration: underline;
}

.dark-link {
  color: $unknown-blue-2-mayday;
}

.dark-link:hover {
  text-decoration: none;
}
</style>
