<template>
  <div>
    <div
      class="entity-container"
      v-for="option in permissionsOptions"
      :key="option.entity"
    >
      <div class="entity-title">
        {{ $t(`settings.integration.mayday-api.entities.${option.entity}`) }}
      </div>
      <MaydayApiPermissionsCheckboxes
        :app-permissions="permissionsByEntity[option.entity] || []"
        :entity-permissions="option.subs"
        @change="handleChange($event, option.entity)"
      />
    </div>
  </div>
</template>

<script>
import MaydayApiPermissionsCheckboxes from './MaydayApiPermissionsCheckboxes.vue';
export default {
  name: 'MaydayApiPermissionsSelector',
  components: {
    MaydayApiPermissionsCheckboxes,
  },
  props: {
    permissions: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.permissionsByEntity = this.permissions.reduce((acc, rawPermission) => {
      const [entity, sub] = rawPermission.split(':');
      if (!acc[entity]) {
        acc[entity] = [];
      }
      acc[entity].push(sub);
      return acc;
    }, {});
  },
  data() {
    return {
      permissionsByEntity: {},
      permissionsOptions: [
        {
          entity: 'knowledges',
          subs: ['read'],
        },
        {
          entity: 'contents',
          subs: ['read', 'update'],
        },
        {
          entity: 'collections',
          subs: ['read'],
        },
        {
          entity: 'contentLabels',
          subs: ['read'],
        },
        {
          entity: 'tasks',
          subs: ['read', 'update'],
        },
        {
          entity: 'users',
          subs: ['create', 'read', 'update', 'delete'],
        },
        {
          entity: 'groups',
          subs: ['read'],
        },
        {
          entity: 'roles',
          subs: ['read'],
        },
        {
          entity: 'userLabels',
          subs: ['read'],
        },
      ],
      subs: ['create', 'read', 'update', 'delete'],
    };
  },
  methods: {
    handleChange(newPermissions, entity) {
      if (!this.permissionsByEntity[entity]) {
        this.permissionsByEntity[entity] = [];
      }
      this.permissionsByEntity[entity] = newPermissions;

      const newPermissionsArray = Object.keys(this.permissionsByEntity).reduce(
        (acc, entity) => {
          return acc.concat(
            this.permissionsByEntity[entity].map((sub) => `${entity}:${sub}`),
          );
        },
        [],
      );

      this.$emit('change', newPermissionsArray);
    },
  },
};
</script>

<style lang="scss" scoped>
.entity-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $grey-4-mayday;
  padding: 8px;
}

.entity-title {
  font-weight: 600;
  font-size: 13px;
  line-height: 1.5;
  color: black;
}
</style>
