<template>
  <div :class="base" v-loading="isLoading">
    <div :class="`${base}__header`">
      <div
        v-for="section in layout"
        :key="section.key"
        :class="[
          `${base}__header__title`,
          section.icon ? `${base}__header__title-icon` : '',
          section.col,
        ]"
      >
        <span v-if="!section.icon">{{ $t(`${pre}.${section.key}`) }}</span>
      </div>
    </div>
    <div :class="`${base}__items-wrapper`">
      <div
        :class="[
          `${base}__items-wrapper__item`,
          { [`${base}__items-wrapper__item-editable`]: mapping.edit },
        ]"
        v-for="mapping in mappings"
        :key="mapping.id"
      >
        <div :class="`${base}__items-wrapper__item col-4`">
          <SsoAttributesMappingInput
            :editable="mapping.edit"
            type="input"
            :value="mapping.idpAttribute"
            :placeholder="$t(`${pre}.idpAttribute`)"
            @input="handleInput(mapping, 'idpAttribute', $event)"
          />
        </div>
        <div :class="`${base}__items-wrapper__item-icon`">
          <font-awesome-icon :icon="['fal', 'equals']" />
        </div>
        <div :class="`${base}__items-wrapper__item col-3`">
          <SsoAttributesMappingInput
            :editable="mapping.edit"
            type="input"
            :value="mapping.value"
            :placeholder="$t(`${pre}.value`)"
            @input="handleInput(mapping, 'value', $event)"
          />
        </div>
        <div :class="`${base}__items-wrapper__item-icon`">
          <font-awesome-icon :icon="['fal', 'arrow-right']" />
        </div>
        <div :class="`${base}__items-wrapper__item col-3`">
          <SsoAttributesMappingInput
            :editable="mapping.edit"
            type="select"
            :options="selectableProfiles"
            :value="resolveProfile(mapping.companyProfileId)"
            :placeholder="$t(`${pre}.companyProfileId`)"
            @input="
              handleInput(
                mapping,
                'companyProfileId',
                $event ? $event.id : null,
              )
            "
          />
        </div>
        <div :class="`${base}__items-wrapper__item-cta col-1`">
          <BaseIconButton
            v-if="!mapping.edit"
            icon="pencil"
            variant="tertiary"
            size="sm"
            @click="handleEdit(mapping)"
            class="ml-1"
          />
          <BaseIconButton
            v-if="!mapping.edit"
            icon="trash"
            variant="tertiary"
            size="sm"
            @click="handleDelete(mapping)"
            class="ml-1"
          />
          <BaseIconButton
            v-if="mapping.edit"
            icon="save"
            variant="tertiary"
            size="sm"
            @click="handleSave(mapping)"
            class="ml-1"
          />
          <BaseIconButton
            v-if="mapping.edit"
            icon="times"
            variant="tertiary"
            size="sm"
            @click="handleCancel(mapping)"
            class="ml-1"
          />
        </div>
      </div>
    </div>
    <div :class="`${base}__add-btn`">
      <base-button
        variant="primary"
        size="sm"
        :text="$t(`${pre}.add`)"
        :disabled="ongoingEdition"
        @click="handleAddMapping"
      ></base-button>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import SsoAttributesMappingInput from './SsoAttributesMappingInput.vue';
import BaseIconButton from '../../../../../components/BaseIconButton.vue';
import { v4 as uuid } from 'uuid';

const base = 'sso-attributes-mapping__profile-mgr';
const i18nPrefix =
  'settings-layout.menu-data.authentication.sso-mapping.drawer.profile';

export default {
  name: 'SsoAttributesMappingProfile',
  components: {
    BaseIconButton,
    SsoAttributesMappingInput,
  },
  data() {
    return {
      base: `${base}`,
      pre: `${i18nPrefix}`,
      isLoading: true,
      profiles: [],
      mappings: [],
      layout: [
        {
          key: 'idpAttribute',
          icon: false,
          col: 'col-4',
          inputType: 'input',
        },
        {
          key: 'operator',
          icon: true,
        },
        {
          key: 'value',
          icon: false,
          col: 'col-3',
        },
        {
          key: 'arrow',
          icon: true,
        },
        {
          key: 'companyProfileId',
          icon: false,
          col: 'col-3',
        },
        {
          key: 'cta',
          icon: true,
          cta: true,
          col: 'col-1',
        },
      ],
    };
  },
  computed: {
    selectableProfiles() {
      return this.profiles.map((g) => ({
        ...g,
        label: g.name,
        key: String(g.id),
      }));
    },
    profilesMap() {
      return this.selectableProfiles.reduce((a, v) => {
        a[v.id] = v;
        return a;
      }, {});
    },
    ongoingEdition() {
      return !!this.mappings.find((el) => el.edit);
    },
  },
  methods: {
    handleAddMapping() {
      this.mappings.push({
        id: uuid(),
        new: true,
        idpAttribute: null,
        operator: 'equal',
        value: null,
        companyProfileId: null,
        edit: true,
      });
    },
    async handleCancel(mapping) {
      if (mapping.new) {
        this.mappings.pop();
      } else {
        await this.refreshMappings();
      }
    },
    async handleDelete(mapping) {
      this.isLoading = true;

      try {
        await this.deleteSsoProfileMapping(mapping.id);
        await this.refreshMappings(false);
      } catch (_err) {
        this.$message({
          message: this.$t(`${this.pre}.errors.delete`),
          type: 'error',
        });
        console.log(_err);
      } finally {
        this.isLoading = false;
      }
    },
    handleEdit(mapping) {
      const mappingIdx = this.mappings.findIndex((el) => el.id === mapping.id);
      if (!this.ongoingEdition)
        this.setMappingValueAtIdx(mappingIdx, { ...mapping, edit: true });
    },
    handleInput(mapping, key, value) {
      mapping[key] = value;
    },
    async handleSave(mapping) {
      this.isLoading = true;

      const method = mapping.new
        ? this.createSsoProfileMapping
        : this.updateSsoProfileMapping;

      try {
        await method(mapping);
        await this.refreshMappings(false);
      } catch (_err) {
        this.$message({
          message: this.$t(
            `${this.pre}.errors.${mapping.new ? 'create' : 'update'}`,
          ),
          type: 'error',
        });
        console.log(_err);
      } finally {
        this.isLoading = false;
      }
    },
    setMappingValueAtIdx(idx, value) {
      const newMapping = [...this.mappings];
      newMapping[idx] = value;
      this.mappings = newMapping;
    },
    async refreshMappings(loader = true) {
      if (loader) this.isLoading = true;
      try {
        const rawMappings = await this.getSsoProfileMappings();
        this.mappings = rawMappings.results.map((el) => ({
          ...el,
          former: el,
        }));
      } catch (_err) {
        console.log(_err);
      } finally {
        if (loader) this.isLoading = false;
      }
    },
    resolveProfile(profileId) {
      const profile = this.profilesMap[profileId];
      if (!profile) return { label: this.$t(`${i18nPrefix}.unknown`) };
      return profile;
    },
    ...mapActions('adminModule', [
      'createSsoProfileMapping',
      'getSsoProfiles',
      'getSsoProfileMappings',
      'updateSsoProfileMapping',
      'deleteSsoProfileMapping',
    ]),
  },
  async mounted() {
    try {
      const rawProfiles = await this.getSsoProfiles();
      this.profiles = rawProfiles.results;
      await this.refreshMappings(false);
    } catch (_err) {
      console.log(_err);
    } finally {
      this.isLoading = false;
    }
  },
};
</script>
<style lang="scss" scoped>
.sso-attributes-mapping__profile-mgr {
  &__header {
    display: flex;
    margin: 8px 0px;
    &__title {
      padding: 0px;
      font-size: 14px;
      font-weight: bold;
      &-icon {
        display: flex;
        justify-content: center;
        width: 34px;
      }
    }
  }
  &__items-wrapper {
    display: flex;
    flex-direction: column;
    &__item {
      display: flex;
      margin: 4px 0px;
      padding: 0px;

      &-icon,
      &-cta {
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 34px;
      }

      &-cta {
        visibility: hidden;

        &__icon {
          cursor: pointer;
          &:hover {
            color: $blue-mayday;
          }

          &-error {
            &:hover {
              color: $red-mayday;
            }
          }
        }
      }

      &-editable > &-cta {
        visibility: initial;
      }

      &:hover > &-cta {
        visibility: initial;
      }
    }
  }
  &__add-btn {
    margin: 4px 0px;
  }
}
</style>
