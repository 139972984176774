<template>
  <div class="private-knowledge-container">
    <PrivateKnowledgeHeader />
    <div class="private-knowledge-layout-wrapper">
      <div class="private-knowledge-layout">
        <div class="private-knowledge-menu-wrapper">
          <SettingsMenu
            :data="menuItems"
            knowledge-type="private"
          />
        </div>
        <div
          ref="settings-list"
          class="private-knowledge-wrapper"
          @scroll="handleScroll"
          @scrollend="focus = false"
        >
          <div v-for="item in flatSubItems" :key="item.title">
            <settings-item
              :id="`settings-item_${item.name}_${item.hash.substring(1)}`"
              :ref="item.hash"
              :active="activeRoute === item.hash"
              :item="{ title: item.title, icon: item.icon, hash: item.hash }"
            >
              <component :is="item.component" :knowledge="focusAdminKnowledge" />
            </settings-item>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SettingsItem from '../../../components/Settings/SettingsItem.vue';
import SettingsMenu from "components/Settings/SettingsMenu.vue";
import PrivateKnowledgeHeader from './PrivateKnowledgeHeader.vue';

import PrivateKnowledgeLanguageSettings from './PrivateKnowledgeLanguageSettings.vue';

const HASH_FALLBACK = {
  configuration: 'language',
};

export default {
  name: 'PrivateSettings',
  components: {
    SettingsItem,
    SettingsMenu,
    PrivateKnowledgeHeader,
    PrivateKnowledgeLanguageSettings,
  },
  data() {
    return {
      activeRoute: null,
      menuItems: [
        {
          title: 'private-knowledge.configuration.title',
          name: 'configuration',
          children: [
            {
              title: 'private-knowledge.configuration.items.language.title',
              icon: 'globe-africa',
              name: 'configuration',
              hash: '#language',
              component: PrivateKnowledgeLanguageSettings,
            },
          ],
        },
      ],
    };
  },
  computed: {
    flatSubItems() {
      return this.menuItems.reduce((acc, item) => {
        return acc.concat(item.children);
      }, []);
    },
    ...mapGetters('adminModule', ['focusAdminKnowledge']),
  },
  methods: {
    focusSetting(route) {
      if (this.scroll) return;
      const { path, hash } = route;
      const setting = path.split('/').pop();
      const element = this.$refs[hash];
      this.activeRoute = hash;
      if (!element || !element.length) {
        if (hash) return;
        this.$router.push({ hash: HASH_FALLBACK[setting] || '' });
        return;
      }
      this.focus = true;
      this.$refs['settings-list'].scrollTo({
        top: element[0].offsetTop - 180,
        behavior: 'smooth',
      });
    },
    handleScroll() {
      if (this.focus) return;
      const top = this.$refs['settings-list'].scrollTop + 180;
      const items = this.getItemsDiv();

      let idx = items.findIndex(
        ({ offsetTop, offsetBottom }) =>
          offsetTop <= top && top <= offsetBottom,
      );
      if (idx < 0) return;

      const [, newName, newId] = items[idx].id.split('_');

      const newHash = '#' + newId;
      const { hash } = this.$route;
      if (hash === newHash) return;

      this.scroll = true;
      this.activeRoute = newHash;
      this.$router.push({ name: `public-${newName}`, hash: newHash });
      setTimeout(() => {
        this.scroll = false;
      }, 500);
    },
    getItemsDiv() {
      const itemsEl = document.querySelectorAll(
        `[id^="public-settings-item_"]`,
      );
      return [...itemsEl].map(({ offsetTop, id, offsetHeight }) => ({
        offsetTop,
        id,
        offsetBottom: offsetTop + offsetHeight,
      }));
    },
    ...mapActions('adminModule', ['setFocusAdminKnowledgeId']),
  },
  watch: {
    '$route.params.knowledgeId': {
      handler(newVal) {
        this.setFocusAdminKnowledgeId(newVal);
      },
      immediate: true,
    },
    $route: {
      handler(newVal) {
        this.focusSetting(newVal);
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.private-knowledge-container {
  width: 100%;
  height: 100%;
  display: flex !important;
  flex-direction: column;
  overflow: hidden;
}

.private-knowledge-layout-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  overflow: hidden;
  padding-left: 2px;
  padding-top: 2px;
}

.private-knowledge-layout {
  display: flex;
  flex: 1;
  width: 100%;
  overflow: hidden;
  align-items: stretch;
  background-color: white;
  border-top-left-radius: 16px;
  box-shadow: $shadow-sm-mayday;
}

.private-knowledge-menu-wrapper {
  width: 280px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 24px 32px;
  border-right: $grey-2-mayday 1px solid;
}

.private-knowledge-wrapper {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
}
</style>
